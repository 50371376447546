// .subhid-menu {
//     height: 100px;
//     width: 100%;
//     border-bottom: 1px solid #2d3748; // Modern bir gri tonu
//     transition: background-color 0.3s ease;

//     li {
//         position: relative; // Yan çizgi için gerekli
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         flex-direction: column;
//         height: 100%;
//         cursor: pointer;
//         color: #a0aec0; // Normal renk
//         font-weight: 500;
//         transition: color 0.3s ease;

//         &:hover {
//             color: #63b3ed; // Hover rengi: açık mavi
//         }

//         .mr-2 {
//             margin-right: 8px;
//         }

//         a {
//             text-decoration: none;
//             color: inherit;
//             font-size: 0.9rem;

//             &:hover {
//                 color: inherit;
//             }
//         }
//     }
// }

// .defamenu {
//     border-left: 1px solid #2d3748;
//     background-color: #2d3748;
//     transition: background-color 0.3s ease, width 0.3s ease;
//     box-shadow: -2px 0 8px rgba(0, 0, 0, 0.3);
// }

// Aktif menü için yan çizgi
// .active {
//     background-color: #2d3748;
//     color: #63b3ed !important;

//     &::before {
//         content: '';
//         position: absolute;
//         left: 0; // Sol tarafa hizalama
//         top: 50%; // Yatay olarak ortala
//         transform: translateY(-50%); // Ortalamayı tam ayarlamak için
//         height: 60%; // Çizginin yüksekliğini ayarlayın
//         width: 4px;
//         background-color: #63b3ed;
//         border-top-right-radius: 10px;
//         border-bottom-right-radius: 10px;
//     }
// }

.rotate-0 {
    transform: rotate(0deg);
  }
  
  .rotate-180 {
    transform: rotate(-90deg);
  }
  
  .transition-transform {
    transition: transform 0.3s ease;
    
  }
  

  // .submenu-content-wrapper .active-submenu {
  //   position: relative !important;
  //   color: #63b3ed !important;
  // }
  
  // .submenu-content-wrapper .active-submenu::before {
  //   content: '' !important;
  //   position: absolute !important;
  //   left: -20px !important;
  //   top: 50% !important;
  //   transform: translateY(-50%) !important;
  //   width: 4px !important;
  //   height: 4px !important;
  //   background-color: #3490dc !important;
  //   border-radius: 50% !important;
  // }
  .submenu-content-wrapper {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    transform: translateY(-10px); /* Yukarıda başlayarak gizli */
    // transition: 
    //     max-height 0.3s ease-in-out, 
    //     opacity 0.3s ease-in-out, 
    //     transform 0.3s ease-in-out,
    //     visibility 0.3s ease-in-out;
}

.submenu-open {
    max-height: 500px; /* İçeriğin olası maksimum yüksekliği */
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

